<template>
  <div>
    <header class="container header_white">
      <Navbar logoColor="true"></Navbar>
    </header>
    <Hero></Hero>
    <Footer bg="footer_bg-red"></Footer>
  </div>
</template>


<script>
import {mapState} from "vuex";
import Navbar from "@/components/layout/Navbar"
import Footer from "@/components/layout/Footer"

import Hero from '@/components/legal/Policy'

export default{
  name: "PrivacyPolicy",
    components: {
    Navbar,
    Footer,
    Hero
  },
}
</script>