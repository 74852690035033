<template>
    <section id="legal" class="bg_lighgrey">
        <div class="container">
            <div class="row">
                <div v-if="false" class="col-12 aos-init" data-aos="fade-up" data-aos-duration="1000">
                    <h3 class="title_xl title_lightblue">
                        ПОЛИТИКА ПРИВАТНОСТИ
                    </h3>
                    <p class="text_l mt-3">

                        Поштовани,
                        <br><br>
                        Пред вама се налази Политика приватности којом се регулише прикупљање, коришћење, похрањивање, пренос и друге радње обраде и заштита података о личности, које корисник достави, објави, подели или на други начин пружи Организацији Serbian Researchers или другим корисницима током активности на интернет презентацији и платформи Serbian Researchers (у даљем тексту: <b>Интернет презентација</b>, <b>Интернет платформа</b> или <b>Платформа</b>) (постојеће на интернет адреси: <a href="https://serbianresearchers.com/" class="title_blue">https://www.serbianresearchers.com/</a> ). Молимо вас да пажљиво прочитате ову Политику приватности имајући у виду да се она примењујe на вас, односно на ваше коришћење Платформе.
                        <br><br>
                        Поред Политике приватности, и заједно са њом, примењују се и <a href="/terms-and-conditions" class="title_blue">Правила и услови коришћења</a> (у даљем тексту: <b>Правила</b>) којима се уређује  коришћење Платформе, у складу са релевантним прописима Р. Србије.
                        <br><br>
                        Свака посета Платформи или регистрацијом значи да сте упознати, односно прочитали Политику приватности и <a href="/terms-and-conditions" class="title_blue">Правила</a>, разумели предмет њиховог уређивања и да се слажете са њима у целости. Уколико, пак, они за вас нису прихватљиви, молимо вас да не користите Платформу.
                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        I	ОСНОВНЕ ОДРЕДБЕ
                    </h4>
                    <p class="text_l mt-3">
                        Организација Serbian Researchers има за циљ умрежавање српских истраживача широм света путем креирања платформе за дељење знања и искуства између појединаца који се баве научно-истраживачким радом у Србији и иностранству. Платформа омогућава повезивање истраживача из различитих респективних сфера и области и доприноси размени идеја и сарадњи између универзитета и компанија. Стога, она је, пре свега, намењена физичким лицима која своје професионалне афинитете реализују радом или сарадњом у академским институцијама, научно-истраживачким институтима и развојно-истраживачким центрима унутар компанија. Такође, Платформи може приступити и свако заинтересовано лице у сврху информисања о темама, радионицама и другим садржајима доступним на њој.
                        <br><br>
                        Интернет презентацијом и платформом администрира и управља Удружење СРПСКИ ИСТРАЖИВАЧИ, које обрађује личне податке корисника у складу са овом Политиком приватности, Законом о заштити података о личности (Сл. гласник РС, бр. 87/2018, у даљем тексту: <b>Закон</b>) и другим релевантним прописима Р. Србије.
                        <br><br>
                        Коришћењем Платформе корисник потврђује да је свестан и разуме да ће одређени подаци о личности који се односе на њега, нарочито подаци достављени приликом регистрације (у зависности од врсте и обима које је одредио у поставкама на Платформи), бити објављени и приказани на Платформи, доступни другим корисницима, односно бити јавно доступни на интернету.
                        <br><br>
                        Начелно, Удружење прикупља и обрађује податке које је корисник доставио приликом регистрације на Платформи или пријавом на новости (енг. newsletter). Такође, приликом посете Платформи одређени подаци о кориснику могу бити доступни Удружењу, попут време и државе из које приступа, тип уређаја са ког приступа и др, на основу услуге треће стране, попут <a href="https://analytics.google.com/analytics/web/provision/#/provision" class="title_blue">Google Analytics</a>. Лични подаци се обрађују и чувају у оном временском периоду које је потребно ради остварења сврхе у коју су ти подаци прикупљени. Примера ради, лични подаци достављени приликом регистрације Удружење обрађује све док Регистровани корисник не избрише свој налог. Изузетно, Удружење може и након брисања налога сачувати и користити податке тог Корисника у сврху заштите својих права и легитимних интереса.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        II	ПОДАЦИ О РУКОВАОЦУ ПОДАТАКА
                    </h4>
                    <p class="text_l mt-3">

                        Удружење које администрира и управља овом Платформом је правно лице основано у складу са Законом о удружењима и регистровано пред Агенцијом за привредне регистре Републике Србије.
                        <br><br>
                        Удружење, у својству руковаоца, обрађује податке о личности корисника у вези са коришћењем Платформе. Подаци о Удружењу – Руковаоцу су:
                        <br><br>
                        Назив Удружења: СРПСКИ ИСТРАЖИВАЧИ,
                        Адреса: Господар Јованова 43, 11000 Београд, Србија,
                        МБ: 28321406,
                        ПИБ: 112436772.
                        <br><br>
                        Корисник може контактирати Удружење путем „обичне“ поште на горе наведену адресу или електронском поштом на имејл: <span class="title_blue">info@serbianresearchers.com</span>.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        III	КОМЕ ЈЕ НАМЕЊЕНА ОВА ПОЛИТИКА ПРИВАТНОСТИ
                    </h4>
                    <p class="text_l mt-3">


                        Имајући у виду да корисник може на више начина користити Платформу (посећивањем ради упознавања са њеном садржином, регистрацијом ради коришћења садржаја и функционалности доступних само Регистрованим корисницима, обраћањем Удружењу путем контакт форме ради постављања питања и др.) ова Политика приватности је намењена:
                        <br><br>
                        -Регистрованим корисницима (лице одређено у пар. 3. глави II – Уговорне стране из <a href="/terms-and-conditions" class="title_blue">Правила</a> ),
                        <br>
                        -Посетиоцима (лице одређено у пар. 2. глави II – Уговорне стране из <a href="/terms-and-conditions" class="title_blue">Правила</a>),
                        <br>
                        -лицима која на други начин остварују интеракцију са Удружењем.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        IV	КОЈИ ПОДАЦИ О ЛИЧНОСТИ СЕ ОБРАЂУЈУ, ПО КОМ ПРАВНОМ ОСНОВУ И У КОЈЕ СВРХЕ
                    </h4>
                    <p class="text_l mt-3">


                        Један део Платформе Корисник може прегледати без уноса било каквих личних података. Са друге стране, одређени делови Платформе могу од Корисника затражити неке личне податке, као што је примера ради део у вези са регистрацијом или пријавом на новости (енг. newsletter). Коришћење ових делова Платформе је у потпуности опционо, те давање личних података је добровољно. Уколико се Корисник одлучи да прегледа, односно користи ове делове може постојати обавеза уноса одређених података. Уколико Корисник не унесе тражене податке неће моћи да користи делове Платформе за које се наведени подаци захтевају. Корисник који је дао личне податке (приликом регистрације, пријаве на новости) има право да затражи њихово брисање или измене (при чему Удружење има право да том Кориснику ограничи, односно укине коришћење одређених делова Платформе за које су ти подаци потребни), као и то да затражи информацију о начину чувања и обраде тих података.
                        <br><br>
                        Коришћењем Платформе, Корисник се саглашава и у потпуности прихвата да одређене радње обраде или коришћење личних података, прикупљених путем Платформе, може бити поверено трећим лицима, попут онима која сарађују или пружају подршку Удружењу у вези са радом Платформе и остваривању њених циљева, „хостинг провајдеру“ и IT компанијама у вези са похрањивањем података, функционисањем и унапређењем Платформе, компанијама које се баве аналитиком интернет саобраћаја (попут <a href="/https://analytics.google.com/analytics/web/provision/#/provision" class="title_blue"><i> Google Analytics</i></a>). У таквим случајевима, лични подаци Корисника ће бити доступни трећим лицима само у оном обиму и у сврхе неопходним за остваривање сарадње, односно пружање ових услуга.
                        <br><br>
                        А) Подаци о личности Регистрованог корисника Платформе
                        <br><br>
                        Приликом креирања корисничког налога и коришћења Платформе, Удружење прикупља и обрађује податке о личности Регистрованог корисника које добровољно достави Удружењу, као и податке о личности који се прикупљају аутоматски, односно самим приступом и коришћењем Платформе.
                        <br><br>
                        Подаци о корисничком налогу, односно о кориснику су:
                        <br><br>
                        - идентификациони и контакт подаци, попут: име, презиме, град, држава, имејл, фотографија,
                        <br>
                        - подаци о корисничком профилу, попут: назив позиције или звања, назив институције или компаније, назив сектора, организационог дела/јединице или катедре, област истраживања.
                        <br><br>
                        Подаци о корисничком налогу, односно о кориснику прикупљају се и обрађују у сврхе:
                        регистрације и коришћења Платформе, а по основу пристанка корисника (чл. 12. ст. 1. тач. 1. Закона) и извршења уговора са корисником, односно предузимања радњи на његов захтев пре закључења уговора (чл. 12. ст. 1. тач. 2. Закона),
                        <br><br>
                        - одржавања неопходног нивоа безбедности Платформе, а у циљу остваривања легитимног интереса руковаоца (чл. 12. ст. 1. тач. 6. Закона).
                        <br><br>
                        2. Подаци о интеракцији са Удружењем прикупљају се и обрађују у сврху пружања корисничке подршке, а по основу пристанка корисника (чл. 12. ст. 1. тач. 1. Закона), извршења уговора са корисником (чл. 12. ст. 1. тач. 2. Закона) и легитимног интереса руковаоца (чл. 12. ст. 1. тач. 6. Закона);
                        <br><br>
                        3. Контакт подаци прикупљају се и обрађују у сврху слања обавештења из секције новости (енг. newsletter), а по основу пристанка корисника (чл. 12. ст. 1. тач. 1. Закона). Корисник у сваком тренутку може опозвати дат пристанак слањем изјаве о опозивању на имејл: info@serbianresearchers.com
                        <br><br>
                        Б) Подаци о личности Посетиоца и лица која на други начин остварују интеракцију са Удружењем
                        <br><br>
                        Приликом пријаве на новости (енг. newsletter), остваривања интеракције са Удружењем и коришћења Платформе од стране лица која нису Регистровани корисници, Удружење прикупља и обрађује податке о личности лица које врши интеракцију, као и податке о личности који се прикупљају аутоматски, односно самим приступом и коришћењем Платформе.
                        <br><br>
                        Подаци о овим корисницима су идентификациони и контакт подаци, попут: име, презиме, имејл.
                        <br><br>
                        Ови подаци се прикупљају и обрађују у сврхе:
                        <br><br>
                        - слања обавештења из секције новости, а по основу пристанка Корисника (чл. 12. ст. 1. тач. 1. Закона). Корисник у сваком тренутку може опозвати дат пристанак слањем изјаве о опозивању на имејл: <span class="title_blue"> info@serbianresearchers.com</span>
                        <br>
                        - пружања корисничке подршке, а по основу пристанка корисника (чл. 12. ст. 1. тач. 1. Закона), извршења уговора са корисником, односно предузимања радњи на његов захтев пре закључења уговора (чл. 12. ст. 1. тач. 2. Закона) и легитимног интереса руковаоца (чл. 12. ст. 1. тач. 6. Закона);
                        <br>
                        - одржавања неопходног нивоа безбедности Платформе, а у циљу остваривања легитимног интереса руковаоца (чл. 12. ст. 1. тач. 6. Закона).

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        V	Аналитика интернет саобраћаја <i>(GOOGLE ANALYTICS и др.)</i>
                    </h4>
                    <p class="text_l mt-3">
                        Одређене податке о Кориснику Удружење прикупља и обрађује по аутоматизму, односно самим приступом и коришћењем Платформе од стране Корисника. Ови подаци су претежно техничке природе и у погледу локације Корисника. Помоћу њих се врши анализа начина коришћења Платформе и понашања Корисника када приступа одређеним садржајима, у сврху унапређења и прилагођавања Платформе за боље корисничко искуство,
	                    а по основу остваривања легитимног интереса руковаоца (чл. 12. ст. 1. тач. 6. Закона). Технички подаци су подаци попут: време приступа,
	                    уређај са ког се приступа, <i>IP</i> адреса, <i>proxy</i> сервер, оперативни систем, интернет претраживач.
                        <br><br>
                        Ови подаци доступни су путем услуга треће стране, као што је Google Analytics. Више о начину на који се прикупљају и обрађују ови подаци можете видети у Гугловим <a href="https://policies.google.com/terms?hl=en-US" class="title_blue"> Правилима и условима</a> и <a href="https://policies.google.com/privacy?hl=en-US" class="title_blue"> Политици приватности</a>.
                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        VI	ПРЕНОС И ОТКРИВАЊЕ ПОДАТАКА О ЛИЧНОСТИ ТРЕЋИМ ЛИЦИМА
                    </h4>
                    <p class="text_l mt-3">
                        Удружење може поверити одређене радње обраде, пренети или открити личне податке Корисника трећим лицима у случајевима:
                        пристанка Корисника,
                        по одговарајућем налогу надлежног државног органа,
                        када је то неопходно ради испуњења уговорне обавезе према Кориснику, односно ради извршења и поштовања <a href="/terms-and-conditions" class="title_blue"> Правила</a>,
                        обезбеђивања правилног рада и унапређења Платформе, остваривање циљева, заштите права и легитимних интереса Удружења.
                        <br><br>
                        Примаоци података са којима Удружење може поделити податке о личности су најчешће одређена физичка или правна лица која обрађују податке о личности у својству обрађивача или су им ти подаци доступни, по основу уговорног односа или сарадње и пружања подршке коју остварују са Удружењем, а на основу датих инструкција.
                        <br><br>
                        Примаоци података су:
                        <br><br>
                        -„хостинг провајдер“ компанија на чијем серверу се складиште подаци је GoDaddy Operating Company LLC, United Kingdom,
                        <br>
                        - запослени и други интерни сарадници у оквиру Удружења који извршавају радне обавезе и задатке и друге налоге,
                        <br>
                        - екстерни сарадници на пословима сарадње и подршке рада Организације <i>Serbian Researchers </i> и заштите права Удружења.
                        <br>
                      -  обезбеђивања правилног рада и унапређења Платформе, креирања имејл листе у софтверу за потребе Удружења (у сврху обавештавања Корисника о новостима, изменама и новитетима на Платформи, интеракције са Корисницима и др.) и заштите права Удружења.
                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        VII	ПРЕНОС ПОДАТАКА О ЛИЧНОСТИ У ДРУГУ ДРЖАВУ
                    </h4>
                    <p class="text_l mt-3">


                        Будући да се поједини примаоци података о личности налазе ван територије Републике Србије (попут „хостинг провајдер“ компаније), подаци о личности Корисника се преносе у државе за које се по Закону сматра да обезбеђују примерени ниво заштите података о личности (члан 64. Закона). У случају преноса података о личности у друге државе (које нису обухваћене претходно наведеним), подаци о личности се преносе уз обезбеђење примерених мера заштите из члана 65. Закона, односно у складу са чланом 69. Закона.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        VIII	ТРАЈАЊЕ ОБРАДЕ ПОДАТАКА О ЛИЧНОСТИ
                    </h4>
                    <p class="text_l mt-3">


                        Податке о личности Корисника Удружење обрађује у временском периоду које је потребно да се оствари сврха обраде, односно до опозива пристанка (уколико се обрада заснива на пристанку). Подаци се чувају у трајању које је неопходно да обезбеди несметано коришћење Платформе, тј. извршење уговорне обавезе према Кориснику, ради извршења и поштовања <a href="/terms-and-conditions" class="title_blue"> Правила</a>. Дужа обрада података је могућа само уколико то представља законску обавезу, подношење, остваривања или одбране правног захтева.
                        <br><br>
                        Конкретно, лични подаци Регистрованог корисника се обрађују и чувају све док је регистровани корисник Платформе, односно док не избрише или поднесе захтев за брисање корисничког налога и личних података.
                        <br><br>
                        Регистровани корисник може у било ком тренутку да обрише свој налог, што за последицу ће имати и брисање његових личних података достављених путем регистрације. Корисник се може обратити и Удружењу са захтевом за брисање његових регистрованих личних података, што за последицу ће имати брисање налога. Удружење ће личне податке обрисати у најкраћем могућем року. Због могућег чувања резервних података (backup) овај рок може бити и до 30 дана.
                        <br><br>
                        Лични подаци Корисника који се пријавио на новости (енг. newsletter) обрађују се и чувају све док корисник не повуче своју сагласност, тј. док не изврши одјаву. Одјава се може извршити слањем имејла на адресу: <span class="title_blue">info@serbianresearchers.com</span>

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        IX	ПРАВА КОРИСНИКА У ПОГЛЕДУ ПОДАТАКА О ЛИЧНОСТИ
                    </h4>
                    <p class="text_l mt-3">


                        1. Право на приступ
                        <br><br>
                        Корисник има право да буде информисан о томе да ли Удружење обрађује његове податке о личности, уколико да, о начину на који обрађује податке о личности и приступ тим подацима. Информације о начину обраде података о личности садржане су у овој Политици приватности.
                        <br><br>
                        Такође, Корисник има право да му Удружење достави све податке о личности које о њему обрађује.
                        <br><br>
                        2. Право на исправку и допуну
                        <br><br>
                        Корисник има право да захтева од Удружења да се његови нетачни подаци о личности исправе, односно да своје непотпуне податке о личности допуни.
                        <br><br>
                        3. Право на брисање
                        <br><br>
                        Корисник има право да поднесе захтев за брисање његових података о личности. Ограничења од овог права постоје у случајевима када Удружења обрађује податке о личности Корисника по основу поштовања законске обавезе или подношења, остваривања или одбране правног захтева и легитимних интереса.
                        <br><br>
                        4. Право на ограничење обраде
                        <br><br>
                        Корисник има право да од Удружења захтева ограничење обраде његових података о личности, уколико:
                        <br><br>
                        - Корисник оспорава тачност података о личности,
                        <br>
                        - је обрада незаконита, а Корисник се противи брисању података о личности и уместо брисања захтева ограничење употребе података,
                        <br>
                        - Удружењу више нису потребни подаци о личности за остваривање сврхе обраде, али их је Корисник затражио у циљу подношења, остваривања или одбране правног захтева,
                        <br>
                        - Корисник је поднео приговор на обраду, а у току је процењивање да ли правни основ за обраду од стране Удружења претеже над интересима Корисника.
                        <br><br>
                        5. Право на преносивост података
                        <br><br>
                        Корисник има право да прими податке о личности које је претходно доставио Удружењу у структурисаном, уобичајено коришћеном и електронски читљивом облику, ако су заједно испуњени следећи услови:
                        <br><br>
                        -обрада је заснована на пристанку или на основу уговора,
                        <br>
                        -обрада се врши аутоматизовано.
                        <br><br>
                        Ово право обухвата и право Корисника да његови подаци о личности буду непосредно пренети другом руковаоцу од стране Удружења, ако је то технички изводљиво.
                        <br><br>
                        6. Право на приговор
                        <br><br>
                        Корисник има право да поднесе приговор на обраду његових података о личности коју Удружење врши по основу легитимног интереса (у смислу чл. 12. ст. 1. тач. 6. Закона). У случају подношења приговора обрада ће бити прекинута, осим уколико постоје законски разлози за обраду који претежу над интересима, правима и слободама Корисника или су у увези са подношењем, остваривањем или одбраног правног захтева, што ће Удружење предочити.
                        <br><br>
                        Уколико је Корисник поднео приговор у погледу обраде за потребе директног оглашавања, подаци о личности се не могу даље обрађивати у такве сврхе.
                        <br><br>
                        7. Право на опозив пристанка
                        <br><br>
                        Ако се обрада података о личности врши на основу пристанка, Корисник има право на опозив пристанка у било које време. Пристанак се може опозвати у сваком тренутку путем имејла: <span class="title_blue">info@serbianresearchers.com</span>. Опозив пристанка не утиче на допуштеност обраде на основу пристанка пре опозива.
                        <br><br>
                        8. Право на подношење притужбе Поверенику
                        <br><br>
                        Уколико Корисник сматра да Удружење врши обраду његових личних података супротно одредбама Закона, у сваком тренутку може поднети притужбу Поверенику за информације од јавног значаја и заштиту података о личности.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        X	ЗАВРШНЕ ОДРЕДБЕ
                    </h4>
                    <p class="text_l mt-3">


                        Ова Политика приватности је подложна променама. У случају значајних измена (нпр. увођење нове сврхе обраде, умањење права Корисника уколико то допушта закон и др.), Удружење ће све Регистроване кориснике обавестити о изменама на активан начин, на пример путем имејла или јасно уочљивих обавештења (push или pop up нотификација). Измењена Политика приватности ступа на снагу у року од осам дана од дана објављивања на Платформи, те ја за ово време Корисник дужан да се упозна са документом. Удружење може да одреди и дужи рок ступања на снагу. Уколико измене нису, пак, прихватљиве за Корисника, дужан је да покрене процес брисања свог налога. Удружење задржава право да од Корисника затражи поновно прихватање Политике приватности за коришћење Платформе, те да ускрати приступ Кориснику који не прихвати промењену Политику приватности.
                        <br><br>
                        У случају правних спорова у вези са овом Политиком приватности примењује се законодавство Републике Србије.
                        <br><br>
                        Уговорне стране ће настојати да све спорове или несугласице који настану из или у вези са овом Политиком приватности решавају мирним путем. Уколико то није могуће, спорови ће се решавати пред месно и стварно надлежним судом у Р. Србији.
                        <br><br>
                        У Београду, 20.04.2021. године

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        ПИТАЊА, КОМЕНТАРИ, СУГЕСТИЈЕ, ПРИМЕДБЕ:
                    </h4>
                    <p class="text_l mt-3">


                        У сваком тренутку, стојимо на располагању за Ваша питања, коментаре, сугестије, запажања, идеје, примедбе и приговоре, те нас слободно контактирајте у вези са овом Политиком приватности, <a href="/terms-and-conditions" class="title_blue"> Правила</a> и Платформом, путем Интернет презентације <a href="http://serbianresearchers.com" class="title_blue"> www.serbianresearchers.com</a> или путем имејла: <span class="title_blue">info@serbianresearchers.com</span>.

                    </p>

                </div>

                <div class="col-12 aos-init" data-aos="fade-up" data-aos-duration="1000">
                    <h3 class="title_xl title_lightblue">
                        POLITIKA PRIVATNOSTI
                    </h3>

                  <h4 class="title_s mt-5 title_lightblue">
                    UKRATKO
                  </h4>

                  <ul class="text_l mt-3">
                    <li>
                    Podaci korisnika se prikupljaju, obrađuju i koriste samo u svrhe i za potrebe rada, funkconisanja i unapređenja Platforme, i zaštite prava Udruženja.
                    </li>
                    <li>
                    Postoje nekoliko svrha obrade podataka: registracija istraživača, prijava na novosti (eng. newsletter), interakcija sa korisnikom, korišćenje Platforme i analitika internet saobraćaja.
                    </li>
                    <li>
                    Postoje nekoliko osnova obrade podataka: pristanak korisnika (čl. 12. st. 1. tač. 1) Zakona), izvršenje ugovora sa korisnikom, odnosno preduzimanja radnji na njegov zahtev pre zaključenja ugovora (čl. 12. st. 1. tač. 2. Zakona), u cilju poštovanja pravnih obaveza rukovaoca (čl. 12. st. 1. tač. 3. Zakona), u cilju ostvarivanja legitimnih interesa rukovaoca (čl. 12. st. 1. tač. 6. Zakona).
                    </li>
                    <li>
                    Podatke o ličnosti obrađuje Udruženje, a dostupni su osnivačima, zaposlenima i drugim internim saradnicima u svrhu preduzimanja poslova iz njihovih delokruga, a eksternim saradnicima samo na poslovima rada i podrške Platforme i Udruženja.
                    </li>
                    <li>
                    Vreme korišćenja i čuvanja podataka ograničeni su svrhom u koju su ti podaci i dati. Primera radi, podaci dati prilikom registracije, obrađuju se za vreme dok je korisnik registrovan. Kada istraživač izbriše nalog, po isteku 30 dana prestaće se i sa korišćenjem njegovih podataka. Isto važi i kod podataka datih prijavom na novosti. Izuzetak predstavljaju situacije propisane zakonom i prilikom zaštite prava.
                    </li>
                    <li>
                    Korisnik ima pravo na pristup svojim podacima o ličnosti, pravo na ispravku, dopunu i brisanje, kao i druga prava propisana Zakonom.
                    </li>
                    <li>
                    Ova Politika privatnosti se može nekad promeniti, te korisnik ukoliko nije saglasan sa izmenjenom Politikom privatnosti može u svakom trenutku da izbriše nalog, odnosno da više ne posećuje Platformu.
                    </li>
                  </ul>
                  <hr class="mt-4">
                    <p class="text_l mt-5">

                        Poštovani,
                        <br><br>
                        Pred vama se nalazi Politika privatnosti kojom se reguliše prikupljanje, korišćenje, pohranjivanje, prenos i druge radnje obrade i zaštita podataka o ličnosti, koje korisnik dostavi, objavi, podeli ili na drugi način pruži Organizaciji Serbian Researchers ili drugim korisnicima tokom aktivnosti na internet prezentaciji i platformi Serbian Researchers (u daljem tekstu: <b>Internet prezentacija</b>, <b>Internet platforma</b> ili <b>Platforma</b>) (postojeće na internet adresi: <a href="https://serbianresearchers.com/" class="title_blue">https://www.serbianresearchers.com/</a> ). Molimo vas da pažljivo pročitate ovu Politiku privatnosti imajući u vidu da se ona primenjuje na vas, odnosno na vaše korišćenje Platforme.
                        <br><br>
                        Pored Politike privatnosti, i zajedno sa njom, primenjuju se i <a href="/terms-and-conditions" class="title_blue">Pravila i uslovi korišćenja</a> (u daljem tekstu: <b>Pravila</b>) kojima se uređuje  korišćenje Platforme, u skladu sa relevantnim propisima R. Srbije.
                        <br><br>
                        Svaka poseta Platformi ili registracijom znači da ste upoznati, odnosno pročitali Politiku privatnosti i <a href="/terms-and-conditions" class="title_blue">Pravila</a>, razumeli predmet njihovog uređivanja i da se slažete sa njima u celosti. Ukoliko, pak, oni za vas nisu prihvatljivi, molimo vas da ne koristite Platformu.
                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        I OSNOVNE ODREDBE
                    </h4>
                    <p class="text_l mt-3">
                        Organizacija Serbian Researchers ima za cilj umrežavanje srpskih istraživača širom sveta putem kreiranja platforme za deljenje znanja i iskustva između pojedinaca koji se bave naučno-istraživačkim radom u Srbiji i inostranstvu. Platforma omogućava povezivanje istraživača iz različitih respektivnih sfera i oblasti i doprinosi razmeni ideja i saradnji između univerziteta i kompanija. Stoga, ona je, pre svega, namenjena fizičkim licima koja svoje profesionalne afinitete realizuju radom ili saradnjom u akademskim institucijama, naučno-istraživačkim institutima i razvojno-istraživačkim centrima unutar kompanija. Takođe, Platformi može pristupiti i svako zainteresovano lice u svrhu informisanja o temama, radionicama i drugim sadržajima dostupnim na njoj.
                        <br><br>
                        Internet prezentacijom i platformom administrira i upravlja Udruženje SRPSKI ISTRAŽIVAČI, koje obrađuje lične podatke korisnika u skladu sa ovom Politikom privatnosti, Zakonom o zaštiti podataka o ličnosti (Sl. glasnik RS, br. 87/2018, u daljem tekstu: <b>Zakon</b>) i drugim relevantnim propisima R. Srbije.
                        <br><br>
                        Korišćenjem Platforme korisnik potvrđuje da je svestan i razume da će određeni podaci o ličnosti koji se odnose na njega, naročito podaci dostavljeni prilikom registracije (u zavisnosti od vrste i obima koje je odredio u postavkama na Platformi), biti objavljeni i prikazani na Platformi, dostupni drugim korisnicima, odnosno biti javno dostupni na internetu.
                        <br><br>
                        Načelno, Udruženje prikuplja i obrađuje podatke koje je korisnik dostavio prilikom registracije na Platformi ili prijavom na novosti (eng. newsletter). Takođe, prilikom posete Platformi određeni podaci o korisniku mogu biti dostupni Udruženju, poput vreme i države iz koje pristupa, tip uređaja sa kog pristupa i dr, na osnovu usluge treće strane, poput <a href="https://analytics.google.com/analytics/web/provision/#/provision" class="title_blue">Google Analytics</a>. Lični podaci se obrađuju i čuvaju u onom vremenskom periodu koje je potrebno radi ostvarenja svrhe u koju su ti podaci prikupljeni. Primera radi, lični podaci dostavljeni prilikom registracije Udruženje obrađuje sve dok Registrovani korisnik ne izbriše svoj nalog. Izuzetno, Udruženje može i nakon brisanja naloga sačuvati i koristiti podatke tog Korisnika u svrhu zaštite svojih prava i legitimnih interesa.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        II	PODACI O RUKOVAOCU PODATAKA
                    </h4>
                    <p class="text_l mt-3">

                        Udruženje koje administrira i upravlja ovom Platformom je pravno lice osnovano u skladu sa Zakonom o udruženjima i registrovano pred Agencijom za privredne registre Republike Srbije.
                        <br><br>
                        Udruženje, u svojstvu rukovaoca, obrađuje podatke o ličnosti korisnika u vezi sa korišćenjem Platforme. Podaci o Udruženju – Rukovaocu su:
                        <br><br>
                        Naziv Udruženja: SRPSKI ISTRAŽIVAČI,
                        Adresa: Gospodar Jovanova 43, 11000 Beograd, Srbija,
                        MB: 28321406,
                        PIB: 112436772.
                        <br><br>
                        Korisnik može kontaktirati Udruženje putem „obične“ pošte na gore navedenu adresu ili elektronskom poštom putem <router-link to="/contact" target="_blank">Kontakt forme</router-link>.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        III	KOME JE NAMENJENA OVA POLITIKA PRIVATNOSTI
                    </h4>
                    <p class="text_l mt-3">


                        Imajući u vidu da korisnik može na više načina koristiti Platformu (posećivanjem radi upoznavanja sa njenom sadržinom, registracijom radi korišćenja sadržaja i funkcionalnosti dostupnih samo Registrovanim korisnicima, obraćanjem Udruženju putem kontakt forme radi postavljanja pitanja i dr.) ova Politika privatnosti je namenjena:
                        <br><br>
                        -Registrovanim korisnicima (lice određeno u par. 3. glavi II – Ugovorne strane iz <a href="/terms-and-conditions" class="title_blue">Pravila</a> ),
                        <br>
                        -Posetiocima (lice određeno u par. 2. glavi II – Ugovorne strane iz <a href="/terms-and-conditions" class="title_blue">Pravila</a>),
                        <br>
                        -licima koja na drugi način ostvaruju interakciju sa Udruženjem.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        IV	KOJI PODACI O LIČNOSTI SE OBRAĐUJU, PO KOM PRAVNOM OSNOVU I U KOJE SVRHE
                    </h4>
                    <p class="text_l mt-3">


                        Jedan deo Platforme Korisnik može pregledati bez unosa bilo kakvih ličnih podataka. Sa druge strane, određeni delovi Platforme mogu od Korisnika zatražiti neke lične podatke, kao što je primera radi deo u vezi sa registracijom ili prijavom na novosti (eng. newsletter). Korišćenje ovih delova Platforme je u potpunosti opciono, te davanje ličnih podataka je dobrovoljno. Ukoliko se Korisnik odluči da pregleda, odnosno koristi ove delove može postojati obaveza unosa određenih podataka. Ukoliko Korisnik ne unese tražene podatke neće moći da koristi delove Platforme za koje se navedeni podaci zahtevaju. Korisnik koji je dao lične podatke (prilikom registracije, prijave na novosti) ima pravo da zatraži njihovo brisanje ili izmene (pri čemu Udruženje ima pravo da tom Korisniku ograniči, odnosno ukine korišćenje određenih delova Platforme za koje su ti podaci potrebni), kao i to da zatraži informaciju o načinu čuvanja i obrade tih podataka.
                        <br><br>
                        Korišćenjem Platforme, Korisnik se saglašava i u potpunosti prihvata da određene radnje obrade ili korišćenje ličnih podataka, prikupljenih putem Platforme, može biti povereno trećim licima, poput onima koja sarađuju ili pružaju podršku Udruženju u vezi sa radom Platforme i ostvarivanju njenih ciljeva, „hosting provajderu“ i IT kompanijama u vezi sa pohranjivanjem podataka, funkcionisanjem i unapređenjem Platforme, kompanijama koje se bave analitikom internet saobraćaja (poput <a href="/https://analytics.google.com/analytics/web/provision/#/provision" class="title_blue"><i> Google Analytics</i></a>). U takvim slučajevima, lični podaci Korisnika će biti dostupni trećim licima samo u onom obimu i u svrhe neophodnim za ostvarivanje saradnje, odnosno pružanje ovih usluga.
                        <br><br>
                        A) Podaci o ličnosti Registrovanog korisnika Platforme
                        <br><br>
                        Prilikom kreiranja korisničkog naloga i korišćenja Platforme, Udruženje prikuplja i obrađuje podatke o ličnosti Registrovanog korisnika koje dobrovoljno dostavi Udruženju, kao i podatke o ličnosti koji se prikupljaju automatski, odnosno samim pristupom i korišćenjem Platforme.
                        <br><br>
                        Podaci o korisničkom nalogu, odnosno o korisniku su:
                        <br><br>
                        - identifikacioni i kontakt podaci, poput: ime, prezime, grad, država, imejl, fotografija,
                        <br>
                        - podaci o korisničkom profilu, poput: naziv pozicije ili zvanja, naziv institucije ili kompanije, naziv sektora, organizacionog dela/jedinice ili katedre, oblast istraživanja.
                        <br><br>
                        Podaci o korisničkom nalogu, odnosno o korisniku prikupljaju se i obrađuju u svrhe:
                        registracije i korišćenja Platforme, a po osnovu pristanka korisnika (čl. 12. st. 1. tač. 1. Zakona) i izvršenja ugovora sa korisnikom, odnosno preduzimanja radnji na njegov zahtev pre zaključenja ugovora (čl. 12. st. 1. tač. 2. Zakona),
                        <br><br>
                        - održavanja neophodnog nivoa bezbednosti Platforme, a u cilju ostvarivanja legitimnog interesa rukovaoca (čl. 12. st. 1. tač. 6. Zakona).
                        <br><br>
                        2. Podaci o interakciji sa Udruženjem prikupljaju se i obrađuju u svrhu pružanja korisničke podrške, a po osnovu pristanka korisnika (čl. 12. st. 1. tač. 1. Zakona), izvršenja ugovora sa korisnikom (čl. 12. st. 1. tač. 2. Zakona) i legitimnog interesa rukovaoca (čl. 12. st. 1. tač. 6. Zakona);
                        <br><br>
                        3. Kontakt podaci prikupljaju se i obrađuju u svrhu slanja obaveštenja iz sekcije novosti (eng. newsletter), a po osnovu pristanka korisnika (čl. 12. st. 1. tač. 1. Zakona). Korisnik u svakom trenutku može opozvati dat pristanak slanjem izjave o opozivanju putem <router-link to="/contact" target="_blank">Kontakt forme</router-link>.
                        <br><br>
                        B) Podaci o ličnosti Posetioca i lica koja na drugi način ostvaruju interakciju sa Udruženjem
                        <br><br>
                        Prilikom prijave na novosti (eng. newsletter), ostvarivanja interakcije sa Udruženjem i korišćenja Platforme od strane lica koja nisu Registrovani korisnici, Udruženje prikuplja i obrađuje podatke o ličnosti lica koje vrši interakciju, kao i podatke o ličnosti koji se prikupljaju automatski, odnosno samim pristupom i korišćenjem Platforme.
                        <br><br>
                        Podaci o ovim korisnicima su identifikacioni i kontakt podaci, poput: ime, prezime, imejl.
                        <br><br>
                        Ovi podaci se prikupljaju i obrađuju u svrhe:
                        <br><br>
                        - slanja obaveštenja iz sekcije novosti, a po osnovu pristanka Korisnika (čl. 12. st. 1. tač. 1. Zakona). Korisnik u svakom trenutku može opozvati dat pristanak slanjem izjave o opozivanju putem <router-link to="/contact" target="_blank">Kontakt forme</router-link>;
                        <br>
                        - pružanja korisničke podrške, a po osnovu pristanka korisnika (čl. 12. st. 1. tač. 1. Zakona), izvršenja ugovora sa korisnikom, odnosno preduzimanja radnji na njegov zahtev pre zaključenja ugovora (čl. 12. st. 1. tač. 2. Zakona) i legitimnog interesa rukovaoca (čl. 12. st. 1. tač. 6. Zakona);
                        <br>
                        - održavanja neophodnog nivoa bezbednosti Platforme, a u cilju ostvarivanja legitimnog interesa rukovaoca (čl. 12. st. 1. tač. 6. Zakona).

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        V	Analitika internet saobraćaja <i>(GOOGLE ANALYTICS i dr.)</i>
                    </h4>
                    <p class="text_l mt-3">
                        Određene podatke o Korisniku Udruženje prikuplja i obrađuje po automatizmu, odnosno samim pristupom i korišćenjem Platforme od strane Korisnika. Ovi podaci su pretežno tehničke prirode i u pogledu lokacije Korisnika. Pomoću njih se vrši analiza načina korišćenja Platforme i ponašanja Korisnika kada pristupa određenim sadržajima, u svrhu unapređenja i prilagođavanja Platforme za bolje korisničko iskustvo,
	                    a po osnovu ostvarivanja legitimnog interesa rukovaoca (čl. 12. st. 1. tač. 6. Zakona). Tehnički podaci su podaci poput: vreme pristupa,
	                    uređaj sa kog se pristupa, <i>IP</i> adresa, <i>proxy</i> server, operativni sistem, internet pretraživač.
                      Više informacija videti u <a href="/cookie-policy" class="title_blue"> Politici kolačića</a>.
                      <br><br>
                        Ovi podaci dostupni su putem usluga treće strane, kao što je Google Analytics. Više o načinu na koji se prikupljaju i
                      obrađuju ovi podaci možete videti u Guglovim
                      <a href="https://policies.google.com/terms?hl=en-US" class="title_blue"> Pravilima i uslovima</a> i
                      <a href="https://policies.google.com/privacy?hl=en-US" class="title_blue"> Politici privatnosti</a>.
                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        VI	PRENOS I OTKRIVANjE PODATAKA O LIČNOSTI TREĆIM LICIMA
                    </h4>
                    <p class="text_l mt-3">
                        Udruženje može poveriti određene radnje obrade, preneti ili otkriti lične podatke Korisnika trećim licima u slučajevima:
                        <br>
                        - pristanka Korisnika, po odgovarajućem nalogu nadležnog državnog organa,
                        - kada je to neophodno radi ispunjenja ugovorne obaveze prema Korisniku, odnosno radi izvršenja i poštovanja <a href="/terms-and-conditions" class="title_blue"> Pravila</a>,
                        <br>
                        - obezbeđivanja pravilnog rada i unapređenja Platforme, kreiranja imejl liste u softveru za potrebe Udruženja (u svrhu obaveštavanja Korisnika o novostima, izmenama i novitetima na Platformi, interakcije sa Korisnicima i dr.) i zaštite prava Udruženja.
                        <br><br>
                        Primaoci podataka sa kojima Udruženje može podeliti podatke o ličnosti su najčešće određena fizička ili pravna lica koja obrađuju podatke o ličnosti u svojstvu obrađivača ili su im ti podaci dostupni, po osnovu ugovornog odnosa ili saradnje i pružanja podrške koju ostvaruju sa Udruženjem, a na osnovu datih instrukcija.
                        <br><br>
                        Primaoci podataka su:
                        <br><br>
                        -„hosting provajder“ kompanija na čijem serveru se skladište podaci je GoDaddy Operating Company LLC, United Kingdom,
                        <br>
                        - zaposleni i drugi interni saradnici u okviru Udruženja koji izvršavaju radne obaveze i zadatke i druge naloge,
                        <br>
                        - eksterni saradnici na poslovima saradnje i podrške rada Organizacije <i>Serbian Researchers </i> i zaštite prava Udruženja.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        VII	PRENOS PODATAKA O LIČNOSTI U DRUGU DRŽAVU
                    </h4>
                    <p class="text_l mt-3">


                        Budući da se pojedini primaoci podataka o ličnosti nalaze van teritorije Republike Srbije (poput „hosting provajder“ kompanije), podaci o ličnosti Korisnika se prenose u države za koje se po Zakonu smatra da obezbeđuju primereni nivo zaštite podataka o ličnosti (član 64. Zakona). U slučaju prenosa podataka o ličnosti u druge države (koje nisu obuhvaćene prethodno navedenim), podaci o ličnosti se prenose uz obezbeđenje primerenih mera zaštite iz člana 65. Zakona, odnosno u skladu sa članom 69. Zakona.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        VIII	TRAJANJE OBRADE PODATAKA O LIČNOSTI
                    </h4>
                    <p class="text_l mt-3">


                        Podatke o ličnosti Korisnika Udruženje obrađuje u vremenskom periodu koje je potrebno da se ostvari svrha obrade, odnosno do opoziva pristanka (ukoliko se obrada zasniva na pristanku). Podaci se čuvaju u trajanju koje je neophodno da obezbedi nesmetano korišćenje Platforme, tj. izvršenje ugovorne obaveze prema Korisniku, radi izvršenja i poštovanja <a href="/terms-and-conditions" class="title_blue"> Pravila</a>. Duža obrada podataka je moguća samo ukoliko to predstavlja zakonsku obavezu, podnošenje, ostvarivanja ili odbrane pravnog zahteva.
                        <br><br>
                        Konkretno, lični podaci Registrovanog korisnika se obrađuju i čuvaju sve dok je registrovani korisnik Platforme, odnosno dok ne izbriše ili podnese zahtev za brisanje korisničkog naloga i ličnih podataka.
                        <br><br>
                        Registrovani korisnik može u bilo kom trenutku da obriše svoj nalog, što za posledicu će imati i brisanje njegovih ličnih podataka dostavljenih putem registracije. Korisnik se može obratiti i Udruženju sa zahtevom za brisanje njegovih registrovanih ličnih podataka, što za posledicu će imati brisanje naloga. Udruženje će lične podatke obrisati u najkraćem mogućem roku. Zbog mogućeg čuvanja rezervnih podataka (backup) ovaj rok može biti i do 30 dana.
                        <br><br>
                        Lični podaci Korisnika koji se prijavio na novosti (eng. newsletter) obrađuju se i čuvaju sve dok korisnik ne povuče svoju saglasnost, tj. dok ne izvrši odjavu. Odjava se može izvršiti slanjem poruke putem <router-link to="/contact" target="_blank">Kontakt forme</router-link>.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        IX	PRAVA KORISNIKA U POGLEDU PODATAKA O LIČNOSTI
                    </h4>
                    <p class="text_l mt-3">


                        1. Pravo na pristup
                        <br><br>
                        Korisnik ima pravo da bude informisan o tome da li Udruženje obrađuje njegove podatke o ličnosti, ukoliko da, o načinu na koji obrađuje podatke o ličnosti i pristup tim podacima. Informacije o načinu obrade podataka o ličnosti sadržane su u ovoj Politici privatnosti.
                        <br><br>
                        Takođe, Korisnik ima pravo da mu Udruženje dostavi sve podatke o ličnosti koje o njemu obrađuje.
                        <br><br>
                        2. Pravo na ispravku i dopunu
                        <br><br>
                        Korisnik ima pravo da zahteva od Udruženja da se njegovi netačni podaci o ličnosti isprave, odnosno da svoje nepotpune podatke o ličnosti dopuni.
                        <br><br>
                        3. Pravo na brisanje
                        <br><br>
                        Korisnik ima pravo da podnese zahtev za brisanje njegovih podataka o ličnosti. Ograničenja od ovog prava postoje u slučajevima kada Udruženja obrađuje podatke o ličnosti Korisnika po osnovu poštovanja zakonske obaveze ili podnošenja, ostvarivanja ili odbrane pravnog zahteva i legitimnih interesa.
                        <br><br>
                        4. Pravo na ograničenje obrade
                        <br><br>
                        Korisnik ima pravo da od Udruženja zahteva ograničenje obrade njegovih podataka o ličnosti, ukoliko:
                        <br><br>
                        - Korisnik osporava tačnost podataka o ličnosti,
                        <br>
                        - je obrada nezakonita, a Korisnik se protivi brisanju podataka o ličnosti i umesto brisanja zahteva ograničenje upotrebe podataka,
                        <br>
                        - Udruženju više nisu potrebni podaci o ličnosti za ostvarivanje svrhe obrade, ali ih je Korisnik zatražio u cilju podnošenja, ostvarivanja ili odbrane pravnog zahteva,
                        <br>
                        - Korisnik je podneo prigovor na obradu, a u toku je procenjivanje da li pravni osnov za obradu od strane Udruženja preteže nad interesima Korisnika.
                        <br><br>
                        5. Pravo na prenosivost podataka
                        <br><br>
                        Korisnik ima pravo da primi podatke o ličnosti koje je prethodno dostavio Udruženju u strukturisanom, uobičajeno korišćenom i elektronski čitljivom obliku, ako su zajedno ispunjeni sledeći uslovi:
                        <br><br>
                        -obrada je zasnovana na pristanku ili na osnovu ugovora,
                        <br>
                        -obrada se vrši automatizovano.
                        <br><br>
                        Ovo pravo obuhvata i pravo Korisnika da njegovi podaci o ličnosti budu neposredno preneti drugom rukovaocu od strane Udruženja, ako je to tehnički izvodljivo.
                        <br><br>
                        6. Pravo na prigovor
                        <br><br>
                        Korisnik ima pravo da podnese prigovor na obradu njegovih podataka o ličnosti koju Udruženje vrši po osnovu legitimnog interesa (u smislu čl. 12. st. 1. tač. 6. Zakona). U slučaju podnošenja prigovora obrada će biti prekinuta, osim ukoliko postoje zakonski razlozi za obradu koji pretežu nad interesima, pravima i slobodama Korisnika ili su u uvezi sa podnošenjem, ostvarivanjem ili odbranog pravnog zahteva, što će Udruženje predočiti.
                        <br><br>
                        Ukoliko je Korisnik podneo prigovor u pogledu obrade za potrebe direktnog oglašavanja, podaci o ličnosti se ne mogu dalje obrađivati u takve svrhe.
                        <br><br>
                        7. Pravo na opoziv pristanka
                        <br><br>
                        Ako se obrada podataka o ličnosti vrši na osnovu pristanka, Korisnik ima pravo na opoziv pristanka u bilo koje vreme. Pristanak se može opozvati u svakom trenutku putem <router-link to="/contact" target="_blank">Kontakt forme</router-link>. Opoziv pristanka ne utiče na dopuštenost obrade na osnovu pristanka pre opoziva.
                        <br><br>
                        8. Pravo na podnošenje pritužbe Povereniku
                        <br><br>
                        Ukoliko Korisnik smatra da Udruženje vrši obradu njegovih ličnih podataka suprotno odredbama Zakona, u svakom trenutku može podneti pritužbu Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti.

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        X	ZAVRŠNE ODREDBE
                    </h4>
                    <p class="text_l mt-3">


                        Ova Politika privatnosti je podložna promenama. U slučaju značajnih izmena (npr. uvođenje nove svrhe obrade, umanjenje prava Korisnika ukoliko to dopušta zakon i dr.), Udruženje će sve Registrovane korisnike obavestiti o izmenama na aktivan način, na primer putem imejla ili jasno uočljivih obaveštenja (push ili pop up notifikacija). Izmenjena Politika privatnosti stupa na snagu u roku od osam dana od dana objavljivanja na Platformi, te ja za ovo vreme Korisnik dužan da se upozna sa dokumentom. Udruženje može da odredi i duži rok stupanja na snagu. Ukoliko izmene nisu, pak, prihvatljive za Korisnika, dužan je da pokrene proces brisanja svog naloga. Udruženje zadržava pravo da od Korisnika zatraži ponovno prihvatanje Politike privatnosti za korišćenje Platforme, te da uskrati pristup Korisniku koji ne prihvati promenjenu Politiku privatnosti.
                        <br><br>
                        U slučaju pravnih sporova u vezi sa ovom Politikom privatnosti primenjuje se zakonodavstvo Republike Srbije.
                        <br><br>
                        Ugovorne strane će nastojati da sve sporove ili nesuglasice koji nastanu iz ili u vezi sa ovom Politikom privatnosti rešavaju mirnim putem. Ukoliko to nije moguće, sporovi će se rešavati pred mesno i stvarno nadležnim sudom u R. Srbiji.
                        <br><br>
                        U Beogradu, 20.04.2021. godine

                    </p>
                    <h4 class="title_s title_lightblue mt-4">
                        PITANJA, KOMENTARI, SUGESTIJE, PRIMEDBE:
                    </h4>
                    <p class="text_l mt-3">


                        U svakom trenutku, stojimo na raspolaganju za Vaša pitanja, komentare, sugestije, zapažanja, ideje, primedbe i prigovore, te nas slobodno kontaktirajte u vezi sa ovom Politikom privatnosti, <a href="/terms-and-conditions" class="title_blue"> Pravila</a> i Platformom, putem Internet prezentacije <a href="http://serbianresearchers.com" class="title_blue"> www.serbianresearchers.com</a>.

                    </p>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
    name: 'Policy',
};
</script>
